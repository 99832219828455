import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fcommon%2FModals%2FAddToCartSuccess%2FAddToCartSuccess.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81Y247jKBB9z1cgrVaaPDDyJbd2pNVo9kuwTWI6GNwYx06v%2Bt9XsU0AX3BnZnY16ienirqcOlUF%2FRWLt0PigX9WAKSkLCi6ReBEcXNcfay%2BtkK%2FFfIrFifK6whkJE0xO64AqEkqswj4nvfn%2FTPD5JxJ9a2OB%2B3xE2cSnlBO6C0CVyS%2BQFjtkKSZX66PSl73Biy51PJS3igeiCtDTN6H0msrpYRhmE0Zr1t5jsSZsAh4OupwChEAXqtSktMNJpxJzGQEygIlGMZY1ngSkzMqIuAHhYHnxg1I6I8B2XmeC4QwdIEQbtwghNtWPlXgR%2F6wxvGFSBjzFgbjE3JBWiSuWEiSIGrKW58JRXkRgaBDuoE9QkG4N1HZPo%2BKJQ%2BcNPklCI3YgCg5M0gkzssIJJhJLB41PxSNphaUfESDXZcwxQ1MicCJJJxFQPDaNqHU94vqwaZwRVWgNCXsDGMuJc8jEHrFJykdc5Fi8TjoFw0oOSWphdE2XutgD22wfaEPYedIIbvdd98FL0mfBaZIkis%2BqgzLTBB2sRryZTL%2FhNMqZ0Ogg13nQVMaVZJroKw6oNZwUomSiwgUnCjAZrB2Fd2ucNxanhguyfRw6Uz0ZYlRcjkLXrF0wMXzWtfEKIZRqITTeyrWqVejOqmG0gB6IqB5tC0QWjXMUu0B%2F%2BceTq2HKQopjfMPEMagxxR7phlbJoJTCmOcoSu5A1%2FmnMtMi2Ik1NBjnJkhRpGalA9Nmxq2fjbNmxmijhpbkVWZIwubuVnYzDf3Zn53jdzAc4%2FcwJ%2FbzK%2BuQSh4Da026uoNrcLOdPDSIFQBXMzh5vsbe7rtDz8y3eh0ZT8b6WAM7azd85jbnjmcchfRmEvIbWFMeXLR0uJ%2F3uOq0BaTVFHmhuiGrY2VGAGvaMB2WDcUl5xWEneTtV%2BanZLo3Cgv3dgVKCVVGYGtZ%2F5s7ss%2F%2Fv5%2B%2F9NgvbnBCuqnr4LBzdl17wsXHc9YE%2BI3u5EFuqVH6y1v1SVuJExxwgXqStiel5ng1TnTiZVtYsv3npnuGy8OZVg%2B1cW6VdX5aoEO%2BfN04E46FAtD%2BM0YwrAnvXXFuf7OBK6fD86SN04C%2F4JAf4rL33KcEgS%2BGE%2BqQ7gpmnWbtfn2toJUXfTxUNr0SmrXboumu61plW2vUmdEYtg2yn0r1AIVx6EDNZDtRtmpH80FpR3sVaCz17WpG2EpkZCdcPi68beWw3HWL1bWfTePtJBK3H7V91ZfhurpnPrMCNAncX9ysHNt43%2BBuJKSs2kvWvM8kdsiIOQzXLnYrtXTbvy400fyYWZqLM6i4rzg9PGHQzdszo1W4csYv5kgOLbe9Exb3HYzjbL0jwcdnxgXacaMP%2Bqxcr4QrrbrrtMP9oyoPUWyj9XHv7RyDHRZFAAA%22%7D"
export var buttonContainer = 'erq8cd';
export var cartSection = 'erq8c9 erq8c0';
export var container = 'erq8c1';
export var ctaButton = 'erq8cb';
export var ctaButtonRemove = 'erq8cc';
export var cutPrice = 'erq8cr';
export var durationPill = 'erq8cp';
export var footerPriceContainer = 'erq8ct';
export var footerSection = 'erq8cs erq8c0';
export var freqButtonContainer = 'erq8ce';
export var freqProductContainer = 'erq8cj erq8c0';
export var freqProductImageContainer = 'erq8ck';
export var freqSection = 'erq8cg erq8c0';
export var freqSectionHeader = 'erq8ci';
export var freqSectionWrap = 'erq8cf';
export var headerContainer = 'erq8c6 erq8c0';
export var hideOnDesktop = 'erq8cn';
export var hideOnTab = 'erq8co';
export var loading = 'erq8ch';
export var mobilePriceContainer = 'erq8cm';
export var price = 'erq8cq';
export var priceContainer = 'erq8cl';
export var productContainer = 'erq8c7 erq8c0';
export var productDetails = 'erq8c5';
export var productImageContainer = 'erq8c8';
export var productInfoWrapper = 'erq8ca erq8c0';
export var productTitle = 'erq8c4';
export var productTitleWrap = 'erq8c3';
export var title = 'erq8c2';
export var totalCutPrice = 'erq8cw';
export var totalPrice = 'erq8cv';
export var totalText = 'erq8cu';