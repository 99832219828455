/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import { ProductData, PriceData } from "data/product/products";
import { Button, CheckmarkIcon, CircledPlusIcon } from "studio-design-system";

import { getRecommendations } from "~/api/checkoutApi";
import { useAuth } from "~/hooks/auth/useAuth";
import { useCart } from "~/hooks/cart/useCart";
import { usePriceOfferInfoCheckout } from "~/hooks/usePriceOfferInfo";
import { getResponsiveImageURL } from "~/util/cloudinary-utils";
import {
  convertSecondsToFriendlyDuration,
  formatMoney,
  getPathForProduct,
  groupedPrice,
} from "~/util/stringUtils";

import { DotSeparatorIcon } from "../../Icons/DotSeparatorIcon";
import { GreenCheckIcon } from "../../Icons/GreenCheckIcon";
import { ImageWithBlur } from "../../ImageWithBlur/ImageWithBlur";
import { FrequentProductCardSkeleton } from "../FrequentProductCardSkeleton";
import * as styles from "./AddToCartSuccess.css";

const PriceDisplay = ({ priceInfo }: { priceInfo: PriceData }) => {
  const { shouldUserSeeOfferCalculation, getProductPrice } = usePriceOfferInfoCheckout();
  const { removeFromCart } = useCart();
  const [shouldShowPriceOffer, setShouldShowPriceOffer] = useState(false);

  useEffect(() => {
    const shouldUserSeeSomeOffer = shouldUserSeeOfferCalculation(priceInfo);
    setShouldShowPriceOffer(shouldUserSeeSomeOffer);
  }, []);

  return (
    <>
      <span className={styles.price}>
        {formatMoney(getProductPrice(priceInfo, shouldShowPriceOffer))}
      </span>
      {shouldShowPriceOffer && (
        <span className={styles.cutPrice}>{formatMoney(priceInfo.crossedPrice)}</span>
      )}
    </>
  );
};

const FrequentProductCard = ({
  product,
  closePopup,
  alreadyInCart,
  onAddToCart,
  removeFromCart,
}: {
  product: ProductData;
  closePopup: () => void;
  alreadyInCart: boolean;
  onAddToCart: (product: ProductData) => void;
  removeFromCart: (id: string) => void;
}) => {
  return (
    <div className={styles.freqProductContainer}>
      <Link href={getPathForProduct(product)} passHref>
        <a className={styles.productInfoWrapper} onClick={() => closePopup()}>
          <div className={styles.freqProductImageContainer}>
            <ImageWithBlur
              style={{ borderRadius: "8px" }}
              src={getResponsiveImageURL(product.productImageURL, "500")}
              alt={product.title}
              layout="fill"
              objectFit="cover"
            />
            <div className={classNames(styles.hideOnDesktop, styles.durationPill)}>
              {convertSecondsToFriendlyDuration(product.totalVideoLengthInSeconds)}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div className={styles.productTitleWrap}>
              <p className={styles.productTitle}> {product.title} </p>
              <div className={styles.hideOnDesktop}>
                {alreadyInCart ? (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeFromCart(product.id);
                    }}
                  >
                    <GreenCheckIcon height={24} width={24} />
                  </div>
                ) : (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onAddToCart(product);
                    }}
                  >
                    <CircledPlusIcon />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.productDetails}>
              {product.creatorName}
              <DotSeparatorIcon />
              <div className={styles.hideOnTab}>
                {convertSecondsToFriendlyDuration(product.totalVideoLengthInSeconds)}
              </div>
              <div className={styles.mobilePriceContainer}>
                <PriceDisplay priceInfo={product.priceInfo} />
              </div>
            </div>
          </div>
        </a>
      </Link>
      <div className={styles.freqButtonContainer}>
        <div className={styles.priceContainer}>
          <PriceDisplay priceInfo={product.priceInfo} />
        </div>

        {alreadyInCart ? (
          <Button
            variant="secondary"
            buttonSize="small"
            onClick={() => removeFromCart(product.id)}
            className={styles.ctaButtonRemove}
          >
            <CheckmarkIcon height={12} width={12} /> Added to cart
          </Button>
        ) : (
          <Button
            variant="secondary"
            buttonSize="small"
            onClick={() => onAddToCart(product)}
            className={styles.ctaButton}
          >
            Add to cart
          </Button>
        )}
      </div>
    </div>
  );
};

export const AddToCartSuccess = ({
  product,
  closePopup,
}: {
  product: ProductData;
  closePopup: () => void;
}) => {
  const router = useRouter();
  const { cartItems, addToCart, addAllToCart, removeFromCart, getCartPathWithQuery } = useCart();
  const [cartIds, setCartIds] = useState<string[]>([]);
  const [recommendedProducts, setRecommendedProducts] = useState<ProductData[]>([]);
  const [allAddedToCart, setAllAddedToCart] = useState(false);
  const { shouldUserSeeOfferCalculation } = usePriceOfferInfoCheckout();
  const [shouldShowPriceOffer, setShouldShowPriceOffer] = useState(false);
  const [frequentProductsLoading, setFreqProductsLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (recommendedProducts) {
      setAllAddedToCart(
        recommendedProducts.filter((x: ProductData) => !cartIds.includes(x.id)).length === 0,
      );
    }
  }, [recommendedProducts, cartIds]);

  useEffect(() => {
    setCartIds(cartItems.map((product) => product.id));
  }, [cartItems]);

  useEffect(() => {
    getRecommendations([product], cartItems, false, user?.userId).then((recommendations) => {
      setRecommendedProducts(recommendations.slice(0, 3));
      setFreqProductsLoading(false);
    });
  }, []);

  const onAddAllToCart = () => {
    if (!allAddedToCart) {
      const filteredProducts = recommendedProducts.filter(
        (x: ProductData) => !cartIds.includes(x.id),
      );
      addAllToCart(filteredProducts);
    } else {
      router.push(getCartPathWithQuery());
    }
  };

  const onAddToCart = (product: ProductData) => {
    if (!cartIds.includes(product.id)) {
      addToCart(product);
    } else {
      router.push(getCartPathWithQuery());
    }
  };

  useEffect(() => {
    const shouldUserSeeSomeOffer = cartItems.some((item) => {
      const shouldUserSeeOffer = shouldUserSeeOfferCalculation(item.priceInfo);
      return shouldUserSeeOffer;
    });
    setShouldShowPriceOffer(shouldUserSeeSomeOffer);
  }, [cartItems, shouldUserSeeOfferCalculation]);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h3 className={styles.title}>Added to cart</h3>
        <GreenCheckIcon />
      </div>
      <div className={styles.cartSection}>
        <div className={styles.productContainer}>
          <div className={styles.productInfoWrapper}>
            <div className={styles.productImageContainer}>
              <ImageWithBlur
                style={{ borderRadius: "8px" }}
                src={getResponsiveImageURL(product.productImageURL, "500")}
                alt={product.title}
                layout="fill"
                objectFit="cover"
              />
            </div>
            <p className={styles.productTitle}>{product.title}</p>
          </div>
          <div className={styles.buttonContainer}>
            <Link href={getCartPathWithQuery()}>
              <Button>Go to cart</Button>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.freqSectionWrap}>
        <div className={styles.freqSection}>
          <h3 className={styles.freqSectionHeader}>Frequently bought together</h3>
          {frequentProductsLoading && <FrequentProductCardSkeleton />}
          {recommendedProducts &&
            recommendedProducts.length > 0 &&
            recommendedProducts.map((product: ProductData, index: number) => (
              <FrequentProductCard
                product={product}
                closePopup={closePopup}
                key={`card_${index}`}
                alreadyInCart={cartIds.includes(product.id)}
                removeFromCart={removeFromCart}
                onAddToCart={onAddToCart}
              />
            ))}
        </div>
      </div>
      {recommendedProducts && recommendedProducts.length > 0 && (
        <div className={styles.footerSection}>
          <div className={styles.footerPriceContainer}>
            <span className={styles.totalText}>Total:</span>
            <span className={styles.totalPrice}>
              {groupedPrice("price", recommendedProducts, shouldUserSeeOfferCalculation)}
            </span>
            {shouldShowPriceOffer && (
              <span className={styles.totalCutPrice}>
                {groupedPrice("crossedPrice", recommendedProducts, shouldUserSeeOfferCalculation)}
              </span>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button onClick={() => onAddAllToCart()}>
              {allAddedToCart ? "Go to cart" : "Add all to cart"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
