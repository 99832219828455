import { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import Head from "next/head";
import { useRouter } from "next/router";

import { classesGenres, classesTopics } from "data/classesTags/classesTags";
import { getFooterData } from "data/footer";
import { HomepageData } from "data/homepage";
import { ProductData } from "data/product/products";
import { Button } from "studio-design-system";
import { useWindowSize } from "usehooks-ts";

import {
  BROWSE_PAGE_VISIT,
  PRODUCT_PAGE_ADD_TO_CART_MODAL_VIEWED,
  PRODUCT_PAGE_ADD_TO_CART_SELECTED,
} from "~/analytics/Amplitude/amplitudeConstants";
import { addUTMPropertiesToEvent } from "~/analytics/Amplitude/amplitudeUtils";
import { useAnalytics } from "~/analytics/useAnalytics";
import { getRecommendations } from "~/api/checkoutApi";
import { getAllProductCollections, getProductCollectionWithSlug } from "~/api/collectionsApi";
import {
  getProducts,
  getMostPopularProducts,
  getNewReleasesProducts,
  SF_MUSIC_SCHOOL_DATA,
} from "~/api/productApi";
import { MarketingFooter } from "~/components/common/MarketingFooter";
import { useAuth } from "~/hooks/auth/useAuth";
import { useCart } from "~/hooks/cart/useCart";
import { useIsMobileScreen } from "~/hooks/ui/useIsMobileScreen";
import { usePriceOfferInfoCheckout } from "~/hooks/usePriceOfferInfo";
import { useSearchProducts } from "~/hooks/useSearchProducts";

import { ClassCardSkeleton } from "../collections/ClassCard";
import { ClassCard } from "../collections/ClassCard/ClassCard";
import { LandingNavbarWithCTA } from "../common/LandingNavbarWithCTA/LandingNavbarWithCTA";
import { AddToCartSuccess } from "../common/Modals/AddToCartSuccess";
import { OverlayModal } from "../common/OverlayModal";
import { TopBannerCTA } from "../common/TopBannerCTA/TopBannerCTA";
import { LandingPageSources } from "../homepage/useLandingPage";
import * as styles from "./Browse.css";
import { BrowseSidebar } from "./components/BrowseSidebar";
import { useQueryState } from "./hooks/useQueryState";
import { CloseIcon, FilterIcon } from "./icons";
import { BrowseQueryState, BrowseSSGData, SetSelectedFilters } from "./types";

const BrowseHead = ({ homepageData }: { homepageData: HomepageData }): React.ReactElement => {
  return (
    <Head>
      <title>{homepageData.meta.ogTitle}</title>
      <meta property="og:title" content={homepageData.meta.ogTitle} />
      <meta name="description" content={homepageData.meta.ogDescription} />
      <meta name="og:description" content={homepageData.meta.ogDescription} />
      <meta property="og:image" content={homepageData.meta.ogImg} />
      <link rel="canonical" href={homepageData.meta.canonicalUrl} />
    </Head>
  );
};

const ExploreClasses = ({
  isHomepage,
  state,
  setQueryParams,
  browseData,
  setClassesCount,
  setShowFilterModal,
  trackAmplitudeEvent,
  setShowAddToCartModal,
}: {
  isHomepage?: boolean;
  state: BrowseQueryState;
  setQueryParams: SetSelectedFilters;
  browseData: BrowseSSGData;
  setClassesCount: (count: number) => void;
  setShowFilterModal: (showFilterModal: boolean) => void;
  trackAmplitudeEvent: <T extends Record<string, any>>(
    trackingEventName: string,
    trackingEventProperties?: T,
  ) => void;
  setShowAddToCartModal: (productData: ProductData) => void;
}) => {
  const productLimit = 24;
  const { user, loadingCurrentUser } = useAuth();
  const isAllClasses = !state.category && state.view === "All classes";

  const searchTerm = state.search ?? "";
  const { searchResults, isLoadingSearchResults, searchError } = useSearchProducts(searchTerm);

  const [foryouProductsState, setForYouProductsState] = useState({
    products: [] as ProductData[],
    isLoading: true,
  });
  const [filteredState, setFilteredState] = useState({
    products: browseData.newReleases,
    lastProductId: "",
    isLoading: true,
    showMoreButton: true,
  });

  const fetchProducts = async ({ loadMore }: { loadMore?: boolean }) => {
    let result = {} as any;
    const hasNoGenresOrTopicsFilter =
      (!state.genres || !state.genres?.length) && (!state.topics || !state.topics?.length);

    if (state.view == "New releases") {
      let collections = null;
      if (!loadMore) {
        //collections = await getProductCollectionWithSlug("all-music");
        //collections = SF_MUSIC_SCHOOL_DATA;
      }
      result = await getNewReleasesProducts(
        false,
        collections ? productLimit - 1 : productLimit,
        loadMore ? filteredState.lastProductId : "",
      );
      if (collections) {
        result.products = [collections, ...result.products];
      }
    } else if (state.view == "Top rated") {
      result = await getMostPopularProducts(
        false,
        productLimit,
        loadMore ? filteredState.lastProductId : "",
      );
    } else if (state.view == "Collections") {
      result = await getAllProductCollections();
      result = {
        products: result,
        totalAvailableCount: result.length,
        isMoreAvailable: false,
      };
    } else {
      let collections = null;
      if (!loadMore && state.category.toLowerCase() == "music") {
        //collections = await getProductCollectionWithSlug("all-music");
        //collections = SF_MUSIC_SCHOOL_DATA;
      }
      result = await getProducts(
        collections ? productLimit - 1 : productLimit,
        loadMore ? filteredState.lastProductId : "",
        state.category,
        state.topics,
        state.genres,
      );
      if (collections && hasNoGenresOrTopicsFilter) {
        result.products = [collections, ...result.products];
      } else if (collections) {
        result.products = [...result.products];
      }
    }

    if (result?.totalAvailableCount) {
      setClassesCount(result.totalAvailableCount);
    } else {
      setClassesCount(
        loadMore ? filteredState.products.length + result.products.length : result.products.length,
      );
    }

    setFilteredState({
      products: loadMore ? [...filteredState.products, ...result.products] : result.products,
      isLoading: false,
      lastProductId: result.products[result.products.length - 1]?.id,
      showMoreButton: result.isMoreAvailable,
    });
  };

  useEffect(() => {
    (async () => {
      if (!loadingCurrentUser) {
        const result = await getRecommendations([], [], true, user?.userId);
        console.log("THE FOR YOU RECS ARE: ", result);
        setForYouProductsState({
          products: result,
          isLoading: false,
        });
      }
    })();
  }, [user?.userId, loadingCurrentUser]);

  useEffect(() => {
    (async () => {
      if (state.category || state.view == "Collections") {
        fetchProducts({});
      } else if (state.view == "Top rated") {
        setFilteredState({
          products: browseData.popularProducts,
          isLoading: false,
          lastProductId: browseData.popularProducts[browseData.popularProducts.length - 1]?.id,
          showMoreButton: false,
        });
      } else if (state.view == "New releases") {
        setFilteredState({
          products: browseData.newReleases,
          isLoading: false,
          lastProductId: browseData.newReleases[browseData.newReleases.length - 1]?.id,
          showMoreButton: browseData.newReleases.length >= 10,
        });
        if (browseData.newReleasesTotalAvailableCount) {
          setClassesCount(browseData.newReleasesTotalAvailableCount);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.category, state.topics, state.genres, state.view]);

  return (
    <section className={classNames(styles.exploreContainer)}>
      {!isAllClasses ? (
        <div className={styles.filterModalButtonWrap}>
          <Button
            variant="secondary"
            buttonSize="small"
            className={styles.modalButton}
            onClick={() => setShowFilterModal(true)}
          >
            <FilterIcon />
            Filter
          </Button>
        </div>
      ) : null}
      {!isHomepage && (
        <h1 className={styles.title}>
          {searchTerm == "" ? <>{state.title}</> : <>{searchResults.length} results</>}
        </h1>
      )}
      {isAllClasses ? (
        <>
          {!isHomepage && (
            <>
              <p className={styles.description}>
                100+ hands-on, online classes so you can learn alongside the world’s best.
              </p>
              <div className={styles.modalButtonWrap}>
                <Button
                  variant="secondary"
                  buttonSize="small"
                  onClick={() => setShowFilterModal(true)}
                  className={styles.modalButton}
                >
                  <FilterIcon />
                  Filter
                </Button>
              </div>
            </>
          )}
          <ClassesSection
            state={state}
            isHomepage={isHomepage}
            products={[...foryouProductsState.products.slice(0, 6)]}
            isLoading={foryouProductsState.isLoading}
            subtitle="Classes for you"
            setQueryParams={setQueryParams}
            trackAmplitudeEvent={trackAmplitudeEvent}
            setShowAddToCartModal={setShowAddToCartModal}
          />
          <ClassesSection
            state={state}
            showMoreClasses
            products={browseData.newReleases}
            newReleasesTotalAvailableCount={browseData.newReleasesTotalAvailableCount}
            subtitle="New classes"
            isHomepage={isHomepage}
            setQueryParams={setQueryParams}
            trackAmplitudeEvent={trackAmplitudeEvent}
            setShowAddToCartModal={setShowAddToCartModal}
          />
          <ClassesSection
            state={state}
            showMoreClasses
            products={browseData.popularProducts}
            isHomepage={isHomepage}
            subtitle="Top rated classes"
            setQueryParams={setQueryParams}
            trackAmplitudeEvent={trackAmplitudeEvent}
            setShowAddToCartModal={setShowAddToCartModal}
          />
        </>
      ) : (
        <>
          {state.search !== "" ? (
            <ClassesSection
              state={state}
              isHomepage={isHomepage}
              products={searchResults}
              isLoading={isLoadingSearchResults}
              setQueryParams={setQueryParams}
              trackAmplitudeEvent={trackAmplitudeEvent}
              setShowAddToCartModal={setShowAddToCartModal}
            />
          ) : (
            <ClassesSection
              state={state}
              showMoreClasses={filteredState.showMoreButton}
              handleLoadMore={() => {
                fetchProducts({ loadMore: true });
              }}
              isLoading={filteredState.isLoading}
              products={filteredState.products}
              setQueryParams={setQueryParams}
              trackAmplitudeEvent={trackAmplitudeEvent}
              setShowAddToCartModal={setShowAddToCartModal}
            />
          )}
        </>
      )}
    </section>
  );
};

export const ClassSectionItem = ({
  productData,
  isHomepage,
  setShowAddToCartModal,
  trackAmplitudeEvent,
  singleColumnGrid,
  isFourColumnsGrid,
}: {
  productData: ProductData;
  isHomepage?: boolean;
  singleColumnGrid?: boolean;
  isFourColumnsGrid?: boolean;
  setShowAddToCartModal: (productData: ProductData) => void;
  trackAmplitudeEvent: <T extends Record<string, any>>(
    trackingEventName: string,
    trackingEventProperties?: T,
  ) => void;
}) => {
  const router = useRouter();
  const { cartItems, addToCart } = useCart();
  const [alreadyInCart, setAlreadyInCart] = useState(false);
  const [showPriceOffer, setShowPriceOffer] = useState(false);
  const { shouldUserSeeOfferCalculation } = usePriceOfferInfoCheckout();
  const isMobileScreen = useIsMobileScreen();

  const onAddToCart = () => {
    trackAmplitudeEvent(PRODUCT_PAGE_ADD_TO_CART_SELECTED, {
      ...addUTMPropertiesToEvent(),
    });
    if (!alreadyInCart) {
      addToCart(productData);
      setShowAddToCartModal(productData);
      trackAmplitudeEvent(PRODUCT_PAGE_ADD_TO_CART_MODAL_VIEWED, {
        ...addUTMPropertiesToEvent(),
      });
    } else {
      const queryParams = router.query;
      // Convert query parameters to a string
      const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");

      const cartPathWithQuery = `/cart?${queryString}`;
      router.push(cartPathWithQuery);
    }
  };

  useEffect(() => {
    const shouldUserSeeOffer = shouldUserSeeOfferCalculation(productData.priceInfo);
    setShowPriceOffer(shouldUserSeeOffer);
  }, [shouldUserSeeOfferCalculation, productData]);

  useEffect(() => {
    setAlreadyInCart(cartItems.filter((prod) => prod.id === productData.id).length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, cartItems]);

  return (
    <ClassCard
      productData={productData}
      onAddToCart={onAddToCart}
      showPriceOffer={showPriceOffer}
      alreadyInCart={alreadyInCart}
      showAddToCart={true}
      isHomePage={isHomepage}
      shouldOpenInNewTab={!isMobileScreen}
      singleColumnGrid={singleColumnGrid}
      isFourColumnsGrid={isFourColumnsGrid}
    />
  );
};

export const ClassesSection = ({
  isHomepage,
  isLoading,
  products,
  subtitle,
  handleLoadMore,
  showMoreClasses,
  state,
  setQueryParams,
  trackAmplitudeEvent,
  setShowAddToCartModal,
  newReleasesTotalAvailableCount,
}: {
  subtitle?: string;
  isHomepage?: boolean;
  isLoading?: boolean;
  products: ProductData[];
  showMoreClasses?: boolean;
  state: BrowseQueryState;
  newReleasesTotalAvailableCount?: number;
  setQueryParams: SetSelectedFilters;
  handleLoadMore?: () => void;
  trackAmplitudeEvent: <T extends Record<string, any>>(
    trackingEventName: string,
    trackingEventProperties?: T,
  ) => void;
  setShowAddToCartModal: (productData: ProductData) => void;
}) => {
  const isMobileScreen = useIsMobileScreen();
  const isMusicSection = state?.category === "Music";

  const combinedstate = [
    ...(state?.topics?.filter((topic) => classesTopics.includes(topic)) || []),
    ...(state?.genres?.filter((genre) => classesGenres.includes(genre)) || []),
  ];

  const handleLoadMoreClasses = () => {
    if (handleLoadMore) {
      handleLoadMore();
    } else if (showMoreClasses && setQueryParams) {
      window.scrollTo(0, 0);
      setQueryParams({
        ...state,
        title:
          subtitle === "New classes"
            ? `New Classes (${products.length})`
            : `Top Rated Classes (${products.length})`,
        view: subtitle === "New classes" ? "New releases" : "Top rated",
      });
    }
  };

  return (
    <>
      <div className={isHomepage ? styles.homepageClassesSection : styles.classesSection}>
        {(isHomepage || !isMobileScreen) && subtitle && (
          <h2 className={isMusicSection ? styles.musicSectionTitle : styles.classesSectionTitle}>
            {subtitle}
          </h2>
        )}
        {isMusicSection && combinedstate.length > 0 ? (
          <div className={styles.selectedTagsContainer}>
            {combinedstate.map((filter, index) => (
              <div
                key={index}
                className={styles.selectedTag}
                onClick={() => {
                  const newstate = { ...state };
                  if (newstate.topics?.includes(filter)) {
                    newstate.topics = newstate.topics.filter((topic) => topic !== filter);
                  } else if (newstate.genres?.includes(filter)) {
                    newstate.genres = newstate.genres.filter((genre) => genre !== filter);
                  }
                  setQueryParams(newstate);
                }}
              >
                {filter}
                <CloseIcon />
              </div>
            ))}
          </div>
        ) : (
          <>
            {state.search ? (
              <div className={styles.selectedTagsContainer}>
                <div
                  className={styles.selectedTag}
                  onClick={() => {
                    const newstate = { ...state };
                    newstate.search = "";
                    newstate.view = "All classes";
                    setQueryParams(newstate);
                  }}
                >
                  {state.search}
                  <CloseIcon />
                </div>
              </div>
            ) : null}
          </>
        )}
        <div className={styles.classesContainer}>
          {products.length > 0 && !isLoading ? (
            products.map((product, index: number) => (
              <ClassSectionItem
                isHomepage={isHomepage || state.view === "All classes"}
                singleColumnGrid={state.view !== "All classes"}
                productData={product}
                key={index}
                trackAmplitudeEvent={trackAmplitudeEvent}
                setShowAddToCartModal={setShowAddToCartModal}
              />
            ))
          ) : isLoading ? (
            <ClassCardSkeleton showAddToCart={true} />
          ) : (
            "No results found"
          )}
        </div>
        {showMoreClasses && !isLoading && products.length > 0 && (
          <div className={styles.showMoreClassesButtonContainer}>
            <Button
              buttonSize="small"
              variant="secondary"
              className={styles.ctaButton}
              onClick={handleLoadMoreClasses}
            >
              Show more classes
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const Browse = ({
  isHomepage,
  homepageData,
  browseData,
}: {
  isHomepage?: boolean;
  homepageData: HomepageData;
  browseData: BrowseSSGData;
}) => {
  const { trackAmplitudeEvent } = useAnalytics();
  const [classesCount, setClassesCount] = useState(0);
  const { state, setQueryParams } = useQueryState({ isHomepage, classesCount });
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showAddToCartModal, setShowAddToCartModal] = useState({
    productData: null as ProductData | null,
    show: false,
  });
  const { width } = useWindowSize();

  const showFilters = useMemo(() => {
    return width > 1200;
  }, [width]);

  return (
    <div className={styles.container}>
      {!isHomepage && (
        <>
          <BrowseHead homepageData={homepageData} />
          <TopBannerCTA heroData={homepageData.heroData} />
          <LandingNavbarWithCTA
            onSignInEvent={() => {
              trackAmplitudeEvent(BROWSE_PAGE_VISIT, addUTMPropertiesToEvent());
            }}
            source={LandingPageSources.Home}
          />
        </>
      )}
      <div className={isHomepage ? styles.homepageInnerContainer : styles.innerContainer}>
        {showFilters && (
          <BrowseSidebar
            state={state}
            isHomepage={isHomepage}
            setQueryParams={setQueryParams}
            setShowFilterModal={setShowFilterModal}
          />
        )}
        <ExploreClasses
          state={state}
          browseData={browseData}
          setClassesCount={setClassesCount}
          isHomepage={isHomepage}
          setQueryParams={setQueryParams}
          setShowFilterModal={setShowFilterModal}
          trackAmplitudeEvent={trackAmplitudeEvent}
          setShowAddToCartModal={(productData: ProductData) =>
            setShowAddToCartModal({
              productData,
              show: true,
            })
          }
        />
        <OverlayModal
          isOpen={showAddToCartModal.show}
          contentClass={styles.overlayModal}
          onClose={() => {
            setShowAddToCartModal({
              productData: null,
              show: false,
            });
          }}
        >
          <>
            {showAddToCartModal.productData && (
              <AddToCartSuccess
                product={showAddToCartModal.productData}
                closePopup={() =>
                  setShowAddToCartModal({
                    productData: null,
                    show: false,
                  })
                }
              />
            )}
          </>
        </OverlayModal>
        {!showFilters && (
          <OverlayModal
            slideUpAnimation
            isOpen={showFilterModal}
            contentClass={styles.overlayFilterModal}
            onClose={() => {
              setShowFilterModal(false);
            }}
          >
            <BrowseSidebar
              state={state}
              setQueryParams={setQueryParams}
              setShowFilterModal={setShowFilterModal}
            />
          </OverlayModal>
        )}
      </div>
      {!isHomepage && <MarketingFooter data={getFooterData()} />}
    </div>
  );
};

export default Browse;
