import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DotSeparatorIcon } from "../../Icons/DotSeparatorIcon";
import * as styles from "../AddToCartSuccess/AddToCartSuccess.css";

const CARDS_COUNT = 3;

export const FrequentProductCardSkeleton = (): React.ReactElement => (
  <>
    {[...Array(CARDS_COUNT)].map((_, i) => (
      <div className={styles.freqProductContainer} key={i}>
        <div className={styles.productInfoWrapper}>
          <div className={styles.freqProductImageContainer}>
            <Skeleton width={"100%"} height={"100%"} borderRadius={8} baseColor={"#EEEEEE"} />
          </div>
          <div style={{ width: "100%" }}>
            <div className={styles.productTitleWrap}>
              <p className={styles.productTitle}>
                <Skeleton width={237} height={32} baseColor={"#EEEEEE"} />
              </p>
              <div className={styles.hideOnDesktop}>
                <Skeleton width={29} height={29} borderRadius={20} baseColor={"#EEEEEE"} />
              </div>
            </div>
            <div className={styles.productDetails}>
              <Skeleton width={50} height={16} baseColor={"#EEEEEE"} />
              <div className={styles.hideOnTab}>
                <Skeleton width={34} height={16} baseColor={"#EEEEEE"} />
              </div>
              <div className={styles.mobilePriceContainer}>
                <Skeleton width={40} height={16} baseColor={"#EEEEEE"} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.freqButtonContainer}>
          <div className={styles.priceContainer}>
            <Skeleton width={47} height={20} baseColor={"#EEEEEE"} />
          </div>

          <Skeleton width={96} height={29} baseColor={"#EEEEEE"} />
        </div>
      </div>
    ))}

    <div className={styles.footerSection}>
      <div className={styles.footerPriceContainer}>
        <span className={styles.totalPrice}>
          <Skeleton width={200} height={29} baseColor={"#EEEEEE"} />
        </span>
      </div>
      <div className={styles.buttonContainer}>
        <Skeleton width={96} height={29} baseColor={"#EEEEEE"} />
      </div>
    </div>
  </>
);
